
.footer-container{
    width: 100%;
    background-color: #1f898f;
    display: flex;
    justify-content: space-between;
    align-items: last baseline;
    height: 10vh;
    color: white;
    font-weight: 100;
}

.footer-title{
    padding-left: 1%;
}

.footer-title h2{   
    font-size: 1vw;
    font-weight: lighter;
}

.footer-social{
    text-align: right;
    width: 30vw;
    /* background-color: red; */
    font-size: 1vw;
    display: flex;
    justify-content: center;
    align-items: last baseline;
}

.footer-social a svg{
    margin: 0vh 0.2vh;
    font-size: 4vh;
    color:rgb(0, 0, 0);
}

.footer-social a{
    margin:1%;
    color: white;
    text-decoration: none;
    border-radius: 50%;
}

@media (max-width: 768px) {
    .footer-container {
        padding: 1%;
        width: 100%;
        /* background-color: red; */
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        line-height: 3vw;
        height: 10vh;
    }
    
    .footer-title h2{
        padding-top: 3%;
        font-size: 3vw;
        /* background-color: red; */
    }

    .footer-social {
        font-size: 3vh;
        
    }
    
    .footer-social a {
        margin: 1%;
    }

    .about-bottom-title{
        font-size: 4vw;
    }
}

/* For even smaller screens */
@media (max-width: 480px) {

    .footer-social a {
        font-size: 2vh; 
    }

    .footer-social a svg {
        font-size: 2.5vh; /* Adjusted font size for better responsiveness */
    }
}