
@import '@fontsource/josefin-sans';

/* Home Page Content  */
body {
  margin: 0; /* Remove default margin for the body */
  font-family: 'Josefin Sans';
  color:white;
}

.app-container {
  min-height: 100vh; /* Ensure it at least covers the full viewport height */
  display: flex;
  flex-direction: column; /* Make the container a flex container with column layout */
}

/* Optional: Add a container to control the width of the logo */
.container {
  /* max-width: 1200px;  */
  /* Adjust the max width as needed */
  margin: 0 auto; /* Center the container */
  padding: 20px; /* Optional: Add padding for spacing */
}

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF; 
  z-index: 1000; 
  position: sticky;
  top: 0;
  border-bottom: 1px solid #E5E5E5;
}

.header .logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.4s ease-in-out;
}

.logo-image {
  max-width: 50%;
}

.logo-text {
  /* font-size: 16px; */
  font-weight: bold;
  font-family: "Josefin Sans", sans-serif;
  color: black;
}

.logo-text p{
  font-size: 1vw;
}

/* Apply the active class to the link within the active li */
.navbar-text ul li a.active {
  color: #1f898f; /* Set the desired color for the active link */
  font-weight: bold; /* Apply other styling as needed */
}

.navbar-text ul li a {
  font-size: 1vw;
}

/* Navbar.css */
.navbar-text {
  font-family: "Josefin Sans", sans-serif; /* Set your desired font-family */
  font-size: 12px; /* Adjust the font size as needed */
  /* margin-bottom: 2%; */
}

.navbar-text ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar-text li {
  margin-right: 20px; /* Adjust the spacing between navbar items */
}

.navbar-text a {
  text-decoration: none;
  color: #333; /* Set your desired text color */
  font-weight: bold; /* Make the text bold */
  transition: color 0.3s; /* Add a smooth color transition effect */
}

.navbar-text a:hover {
  color: #007bff; /* Change the text color on hover to a different color */
}

.navbar ul {
  list-style: none;
  text-align: center;
  /* margin: 85px; */
  margin-left: 20px;
}
.navbar ul li {
  display: inline-block;
}

.navbar ul li a {
  text-decoration: none;
  display: block;
  /* font-size: larger; */
  font-weight: bold;
  font-family: "Josefin Sans", sans-serif;
  color: #000000;
}

.navbar ul li a:hover {
  color: #ede7e7;
}

.slider-background {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3%;
  width: 80%;
  padding: 0 10%;
  justify-content: center;
}

.row {
  display: flex;
  /* justify-content: center; */
  flex-wrap: wrap;
  justify-content: flex-start;
}


.row .book1 {
  width: 25%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease-in-out;
  position: relative; 
}

.row .book1 img {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.row .book1 .hover_div {
  display: none;
  position: absolute;
  top: 50%; 
  left: 50%; 
  transform: translate(-50%, -50%); /* Center the element */
  width: 70%; /* Adjust the width as needed */
  max-width: 300px; /* Set a maximum width */
  padding: 10px; /* Add padding for better readability */
  color: black;
  font-weight: normal;
  background-color: rgba(255, 255, 255, 0.6); 
  font-family: "Josefin Sans", sans-serif;
  font-size: 18px;
  text-align: center; /* Center text */
  line-height: 1.5; /* Increase line height for better spacing */
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.row .book1:hover img {
  /* display: none; */
   opacity: 0;
}

.row .book1:hover .hover_div {
  display: block;
  opacity: 1; /* Make the text visible */
}


/* Add media query for smaller screens */
@media (max-width: 768px) {
  .slider-background {
    padding: 0 10%; /* Remove padding for smaller screens */
  }

  .row .book1 {
    width: 100%;
  }
}

.cover-img {
  background-color: #ffffff;
  margin: 6px;
  position: relative;
  overflow: hidden;
  transition: background-color 0.3s ease-in-out;
  &::after {
    content: "L'art En Coloriages";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  &:hover {
    opacity: 0;
    background-color: #ffffff; /* Change background to transparent */
    &::after {
      opacity: 1; /* Make the text visible */
    }
  }
  @media (max-width: 768px) {
    min-width: 100%; 
    max-width: inherit;
    margin: 8px;
  }
}

/* Start of inside covers */
.cover-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  padding: 0 5%;
}



.inside-page {
  flex: 0 0 100%;
  max-width: 100%;
  margin-bottom: 25px;
}

.coverimg {
  width: 70%;
  height: auto;
}

/* Add spacing for text and paragraphs */
.inside-page:nth-child(1) {
  margin-top: 100px; /* Adjust the margin as needed */
}

/* CSS for medium screens (e.g., tablets) */
@media (max-width: 768px) {
  .coverimg {
    /* max-width: 100%; */
    width: 100%;
    /* height: auto; */
    /* padding: 0 5%; */
  }
}


/* Home Footer Section Style */
.footer {
  position: relative;
  /* Adjust the top and bottom padding as needed */
  font-size: 20px;
  font-weight: lighter;
  color: #000000;
  font-family: "Josefin Sans", sans-serif;
  display: flex;
  flex-direction: column; /* Stack content vertically for responsiveness */
  align-items: center; /* Center horizontally */
  background-color: #1f898f;

  @media (min-width: 768px) {
    /* Add responsive styles for screens wider than 768px */
    flex-direction: row; /* Display content in a row on larger screens */
    justify-content: space-between; /* Separate text and social icons */
    font-size: 24px;
  }
}

.footer-text {
  text-align: center; /* Center text horizontally */
  margin-bottom: 10px; /* Add space between text and social icons */
  font-family: "Josefin Sans", sans-serif;
  font-size: 20px;
}

.footer a {
  text-decoration: none;
  margin: 0 10px; /* Add margin to all sides of social icons */
}

.footer a:first-child {
  margin-left: 0; /* Remove the margin from the first social icon */
}


/* If you want to adjust the spacing between social icons, you can add margin here */
.footer a:not(:last-child) {
  margin-right: 10px; /* Add margin between social icons */
}

/* About Page Content Style */

.row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.content-container {
  background-color: #ffffff;
  padding: 32px;
  border-radius: none;
  max-width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  border: 1px solid black;
  /* align-items: baseline; */
}



/* Media query for screens smaller than a certain width (adjust as needed) */
@media (max-width: 768px) {
  .row-container {
    flex-direction: row; /* Keep items in the same row on smaller screens */
    flex-wrap: wrap; /* Allow items to wrap if necessary */
  }
  .logo-text p{
    font-size: 3vw;
  }

  .navbar-text ul li a{
    font-size: 3vw;
  }
}

.heading {
  color: #1f898f;
  font-family: "Josefin Sans", sans-serif;
  font-size: 20px;
}

.para1 {
  font-family: "Josefin Sans", sans-serif;
  font-size: 18px;
}

.para2 {
  color: #1f898f;
  font-family: "Josefin Sans", sans-serif;
  font-size: 20px;
}

.para2-content {
  font-family: "Josefin Sans", sans-serif;
  font-size: 18px;
}

.para3 {
  color: #1f898f;
  font-size: 18px;
}

.heading2 {
  color: #1f898f;
}

.btn1 {
  font-family: "Josefin Sans", sans-serif;
  font-size: 15px;
  background-color: #fff;
  border: 3px solid #1f898f;
  margin: 15px;
  padding: 8px;
}


/* About footer Section Style*/
.about-footer {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center;
  padding: 20px;
}

.companies-associated {
  text-align: center; /* Center the text horizontally */
}

.about-footer-text {
  display: flex;
  justify-content: center;
  color: #086064; /* Text color */
  font-size: 24px; /* Font size */
  /* margin: 0; Remove default margin */
}

.footer-image {
  max-width: 100%; /* Ensure the image fits within the container */
  height: auto; /* Maintain aspect ratio */
}


/* Contact Section Style */
.contact-container-main {

  background-color: #1F898F;
  display: flex;
  /* height: 100vh; */
  /* flex-wrap: wrap; */
  flex-direction: column;
  align-items: center; /* Center the content vertically */
  padding: 20px;

}

.contact-container {
  background-color: #ffffff;
  padding: 20px;
  border-radius: none;
  max-width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between;  */
  /* height: 800px; */
  /* height: auto; */
  border: 1px solid black;
}

.contact-header {
  margin: 5px;
  font-size: 22px;
  text-align: left;
}

.main-content-container {
  /* margin: 10px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the items horizontally */
  align-items: center; /* Center vertically for smaller screens */
  text-align: center; /* Center the text for smaller screens */
}

.main-heading {
  margin: 10px;
  color: #1f898f;
}


.main-content {
  flex: 1; /* Allow content to grow and shrink */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content for smaller screens */
}

.contact-image-left {
  max-width: 100%; /* Ensure the image doesn't exceed its container's width */
  height: auto; /* Maintain aspect ratio */
  margin: -25px; 
  transform: rotateX('angle');
  z-index: 99;
}

.main-log {
  width: 50px; /* Adjust the logo size as needed */
  height: 50px;
}

.main-image {
   display: flex;
   align-items: flex-start;
} 

.main-text {
  color: #1f898f;
  font-size: 20px;
  margin: 10px 0; /* Add margin for spacing */
}

.main-text2 {
  font-size: 20px;
  margin: 10px 0; /* Add margin for spacing */
}


.contact-form {
  flex: 1; /* Take the remaining available space */
  margin: 5px auto; /* Center the form horizontally */
  padding: 30px;
  text-align: center; /* Center the form elements */
}
/* .form1 {
  position: absolute;
} */

.input-name,
.input-email,
.subject,
.text-area {
  display: block;
  width: 100%; /* Make inputs expand to full width */
  margin: 15px 0; /* Add margin for spacing between inputs */
  padding: 15px;
  border: 2px solid #1f898f;
  font-size: 20px;
  font-family: "Josefin Sans", sans-serif;
  box-sizing: border-box; /* Include padding in width */
}

.button {
  color: #ffffff;
  background-color: #1f898f;
  border-radius: 5px;
  font-size: 20px;
  padding: 15px 30px; /* Add padding for better touch interaction */
  cursor: pointer;
  display: inline-block; 
}


@media only screen and (max-width: 768px) {
  .header .logo .logo-image {
    width: 40vh;
  }

  .slider-background .row1 {
    background-color: #fff;
    display: flex;
    flex-direction: column;
  }
}

.icon-container {
  padding: 5px;
  margin: 5px;
}

.icon-container .images {
  display: flex;
  flex-wrap: wrap;
  /* flex-wrap: wrap; */
  /* flex-direction: column; */
  justify-content: space-between;
  /* justify-content: center; */
  align-items: center;
  max-width: 100%;
  margin: 15px;
  /* margin: 15px;
    padding: 50px; */
}

  .image-container {
    text-align: center;
    flex: 1; /* Distribute available space evenly among image containers */
    max-width: calc(33.33% - 15px); /* Adjust the width of the individual images as needed */
    margin: 10px; /* Add some spacing between images and descriptions */
    width: 350px;
    box-sizing: border-box;
    display: flex; /* Use flex to vertically center the content */
    flex-direction: column; /* Stack image and text vertically */
    align-items: center; /* Center content horizontally */
    height: auto; /* Reset height */
  }

.icon-conc,
.icon-crea,
.icon-con {
  max-width: 100%; /* Adjust the width of the individual images as needed */
  height: auto; /* Maintain aspect ratio */
}

.image-description {
  /* flex-wrap: wrap; */
  /* width: 200px; */
  font-size: 20px;
  font-family: "Josefin Sans", sans-serif;
  /* font-family: Arial, sans-serif; */
  color: #419b9f;
  margin-top: 10px;
  display: block;
  text-align: center; /* Center text horizontally */
  flex: 1; /* Allow text to expand vertically */
  overflow: hidden; /* Hide any overflowed text */
  /* max-height: 6em; */
}

@media screen and (max-width: 768px) {
  .images {
    flex-direction: column;
    align-items: center;
  }

  .image-container {
    max-width: 70%; /* Adjust width for smaller screens */
    margin: 10px; 
    /* margin-bottom: 20px; */
    /* max-width: 70%; */
  }

  .icon-conc,
  .icon-crea,
  .icon-con {
    max-width: 70%;
  }
}
