.sliderContainers{
    width: 100%;
    height:100%;
    /* background-color: white; */
    text-align: left;
    display: flex;
    flex-direction: column;
}

.sliderContainer{
display: flex;
justify-content: center;
align-items: center;
background-color: white;
}

.image_wrapper{
    width: 200px;
    height: 100%;
    /* background-color: red; */
}

.image_wrapper img{
    width: 100%;
    object-fit: cover;
    filter: grayscale(100%);
}

.client-slider-title{
    font-weight: lighter;
    float: left;
    color:rgb(255, 255, 255);
    font-size: 1.6vw;
    padding: 1%;
}

@media (max-width:786px){
    .sliderContainers{
        border: none;
    }
    .image_wrapper{
        width: 120px;
        height: 100%;
        /* background-color: red; */
    }
    
    .image_wrapper img{
        width: 100%;
        object-fit: cover;
    }

    .client-slider-title{
        font-size: 3vw;
    }
    
}