.productViewFirst h1{
    font-size: 1.5vw;
}

.productViewFirst{
    margin: 0 auto;
    width:62.5vw;
    margin: 0vw 13.5vw;
    margin-top: 50px;
    margin-bottom: 30px;
    padding: 3vw 10vw;
    background-color: var(--bg-color);
    color: var(--text-color);
    /* color:black; */
    font-family: Josefin Sans;
}


.productViewFirst_h2{
    font-size: 1vw;
    font-weight: 600;
    padding-bottom: 10px;
}

.productViewFirst_p{
    font-weight: normal;
    font-size: 1vw;
    width: 100%;
    text-align: justify;
    line-height: 1.5vw;
}

.productViewFirst_p .additional-space {
    display: block;
    margin-top: 6px; /* Adjust the margin-top value as needed */
}


@media (max-width:769px){
    .productViewFirst{
        margin: 0px;
        margin-top: 50px;
        margin-bottom: 30px;
        padding: 6vw 20vw;
        width: 90vw;
    }
    .productViewFirst h1{
        font-size: 3vw;
    }
    .productViewFirst_h2{
        font-size: 2.5vw;
    }
    .productViewFirst_p{
        font-size: 2.5vw;
        line-height: 3vw;
    }
}