@media (min-width:786px){
    .contact-container{
        margin: 0px;
        padding: 0px;
        display: flex;
        flex-direction: row;
        /* justify-content: center; */
        align-items: center;
        border-style: none;
        position: relative;
    }
    
    .contact-container-blank{
        height: 20px;
        background-color: #1f898f;
    }
    
    
    .contact-container-column1{
        width: 100%;
        height: 90vh;
        position: relative;
    }
    
    .contact-container-column1 img{
        width: 100%;
        height: 90vh;
        margin: 0px;
        padding: 0px;
        object-fit: cover;
    }
    
    .contact-container-column2{
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-10%,-0%);
        width:50%;
        background-color: transparent;
        height: 90vh;
    }
    
    .contact-container-column2-title{
        color:black;
        font-weight: lighter;
        font-size: 0.8vw;
        line-height: 1.5vw;
        padding: 2%;
    }
    
    .contact-container-column2-title h2{
        font-size: 1.3vw;
        font-weight: lighter;
        line-height: 1.8vw;    
    }
    
    .contact-container-column2-title h1{
        color: #1f898f;
        font-weight: lighter;
        font-size: 1.2vw;
    }
    
    .contact-container-column2-form{
        padding-left: 15%;
    }
    
    .company-details{
        margin: 1%;
        /* padding: 1%; */
        color:black;
        height: 17vh;
        width:30vw;
        display: flex;
        align-items: center;
        /* background-color: red; */
        position: relative;
    }
    
    .company-details h3{
        color:#1f898f;
        font-size: 1.4vw;
        margin-top:15%;
        padding: 0px;
        line-height: 0px;
    }

    .company-details-logo{
        margin: 1px;
        height: 100%    ;
    }
    
    .company-details-logo img{
        /* width:9vw; */
        height: 100%;
    }
    
    .company-details-address{
        font-size: 1.4vw;
        width: min-content;
        line-height: 1.4vw;
    }
}


@media (max-width: 768px) {
    .contact-container-blank{
        height: 10px;
        background-color: #1f898f;
    }

    .contact-container{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 0px;
        margin: 0px;
        padding: 0px;
        /* background-color: palevioletred; */
    }

    .contact-container-column1{
        /* display: none; */
        width: 100%;
        height: 20%;
        /* background-color: red; */
    }

    .contact-container-column1 img{
        /* display: none; */
        width: 100%;
        height: 40vw;
        object-fit: cover;
    }

    .contact-container-column2{
        width: 100%;
        color: black;
        /* background-color: purple; */
        margin: 0px;
        padding: 0px;
        text-align: center;
    }

    .contact-container-column2-title{
        padding-left: 10%;
        text-align: left;
        /* padding: 0px; */
        /* background-color: green; */
    }

    .contact-container-column2-title h2{
        font-size: medium;
        font-weight: lighter;
    }
    .contact-container-column2-title h1{
        font-size: medium;
        font-weight: lighter;
        color:#1f898f;
    }

    .company-details{
        margin: 1%;
        width: 40%;
        height: min-content;
        /* background-color: red; */
    }

    .company-details-logo img{
        width: 40%;
        object-fit: cover;
    }

    .company-details h3{
        font-size: 3vh;
        margin: 2%;
        padding: 0px;
    }

    .company-details-address p{
        font-size: 4vw;
        line-height: 1vh;
    }

    .contact-container-column2-form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2%;
    }

}

