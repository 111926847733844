.form-container {
    width: 30vw;
    height: 40vh;;
}

/* form {
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 300px;
} */

input {
    width: 100%;
    height: 13vh;
    padding: 8px;
    margin-bottom: 16px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}
input:focus,
textarea:focus {
    outline: 2px solid #1f898f;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
input {
    width: 100%;
    height: 4.5vh;
    padding: 8px;
    margin-bottom: 16px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}
textarea {
    width: 100%;
    height: 10vh;
    padding: 8px;
    margin-bottom: 16px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

button {
    background-color: #1f898f;
    color: #fff;
    padding: 10px;
    margin: 0px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

button:hover {
    background-color: #1f898f;
}

@media (max-width:786px){
    .form-container{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}