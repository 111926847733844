.about-containers{
    background-color: #1f898f;
    text-align: center;
    width: 100%;
    /* background-color: red; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.about-title{
    width: 40%;
    font-size: 1.2vw;
    padding: 3vh;
}

.about-containers .about-container:nth-child(3){
    flex-direction: row-reverse;
    /* background-color: red; */
    text-align: right;
}

.about-container{
    width: 40%;
    /* background-color:red; */
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
}

.about-container-title{
    /* background-color: yellow; */
    width: 70%;
    text-align: left;
    font-size: 1.5vw;
    line-height: 2vw;
    padding: 0% 3%;
}

.about-container-title p{
    font-size: 1.3vw;
    font-weight: lighter;
    color: white;
}
.about-container-image{
    height: fit-content;

    border-radius: 50%;
    /* background-color: blue; */
    padding: 1%;
    border: 1px solid rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    align-items: center;
}

.about-container-image img{
    border-radius: 50%;
    width: 10vw;
    height: 10vw;
    margin:0px;
    object-fit: cover;
}

.about-bottom-title{
    font-size: 1.5vw;
    padding: 2%;
}

@media (max-width:786px) {
    .about-containers{
        width: 100%;
        margin: 0px;
        padding: 0px;
        /* background-color: red; */
    }

    .about-title{
        width: 90%;
        font-size: 3.5vw;
    }

    .about-container{
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .about-container-image img{
        width: 30vw;
        height: 30vw;
    }

    .about-container-title{
        font-size: 4vw;
        line-height: 5vw;
    }
    .about-container-title p{
        /* font-family: 'Extra Thin Font'; */
        font-size: 3.5vw;
        font-weight: 100;
    }

    .about-bottom-title{
        font-size: 4vw;
    }
}